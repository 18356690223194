var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { col: "12" } },
            [
              _c("BranchCard", {
                attrs: { "branch-info": _vm.selectedBranch, map: true }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        { staticClass: "pt-1" },
        [
          _c(
            "CCol",
            { attrs: { col: "12" } },
            [
              _c("DevicesView", { attrs: { inventory: _vm.inventory } }),
              false
                ? _c("DashboardCard", {
                    staticClass: "admin-card",
                    attrs: { color: _vm.$theme.backColor, collapsible: true },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                [
                                  _c("CIcon", { attrs: { name: "" } }),
                                  _vm._v(" Devices ")
                                ],
                                1
                              ),
                              _c("span", { staticClass: "float-right" })
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "body",
                          fn: function() {
                            return [
                              _c(
                                "CCardBody",
                                { staticClass: "overflow-none m-0 p-0" },
                                [
                                  _c("DeviceManagementGrid", {
                                    attrs: {
                                      items: _vm.inventory,
                                      small: false,
                                      "show-toggle": false
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3512751031
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }