<template>
  <div>
    <CRow>
      <CCol col="12">
        <BranchCard :branch-info="selectedBranch" :map="true" />
      </CCol>
    </CRow>
    <CRow class="pt-1">
      <CCol col="12">
        <DevicesView :inventory="inventory" />
        <DashboardCard
          v-if="false"
          class="admin-card"
          :color="$theme.backColor"
          :collapsible="true"
        >
          <template #header>
            <span> <CIcon name="" /> Devices </span>
            <span class="float-right"> </span>
          </template>
          <template #body>
            <CCardBody class="overflow-none m-0 p-0">
              <DeviceManagementGrid
                :items="inventory"
                :small="false"
                :show-toggle="false"
              />
            </CCardBody>
          </template>
        </DashboardCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import BranchCard from "@/components/branches/BranchCard";
import DashboardCard from "@/components/dashboard/DashboardCard";
import DeviceManagementGrid from "@/components/tables/DeviceManagementGrid";
import DevicesListView from "@/views/main/device/DevicesListView";
import { inventory } from '@/data/appData';
export default {
  name: "BranchDetailView",
  components: {
    BranchCard,
    DeviceManagementGrid,
    DashboardCard,
    DevicesView :DevicesListView,
  },
  data() {
    return {
      id: undefined,
      selectedBranch: undefined,
    };
  },
  computed: {
    branch() {
      if (!this.$store.getters.branches) return null;

      this.selectBranch = this.$store.getters.branches.find(
        (item) => item.branch_id === this.id
      );
      return this.selectedBranch;
    },
    inventory() {
      
      if (this.$store.getters.inventory) {
        let branch_id = this.id;
        return this.$store.getters.inventory.filter(
          (item) => item.branch_id === branch_id
        );
      }
    },
  },
  methods: {
    async load() {
      try {
        // Get the ID from the router;
        
        this.id = this.$route.params.id || this.$route.query.id;
        if (!this.$store.getters.branches) {
          await this.$app.loadBranches();
        }
        this.selectedBranch = this.$store.getters.branches.find(
          (item) => item.branch_id === this.id
        );
        console.log(this.selectedBranch,this.inventory);
      } catch (err) {
        console.log(err);
      }
    },
  },
  async mounted() {
    await this.load();
  },
  async activated() {
    await this.load();
  },
};
</script>
