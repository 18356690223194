
<template>
  <CRow>
    <CCol sm="12">
      <CCard accent-color="danger">
        <CCardHeader :color="$theme.backColor">
          <div class="default-card-header">
            <CIcon name="cil-wifi-signal-3"> </CIcon>
            Devices
          </div>
        </CCardHeader>
        <CCardBody>
          <DeviceManagementGrid
            :items="inventory"
            :small="false"
            :striped="true"
            :show-toggle="false"
            @clickDetails="toggleDetails"
          >
            <template v-slot:details="item">
              <CCard>
              <CRow class="pb-0 no-gutters device-details-card">
                <CCol col="10" class="pb-0">
                  <DeviceDetails class="h-100 pb-0" :info="getInfo(item)">
                  </DeviceDetails>
                </CCol>
                <CCol col="2">
                  <CCard class="pb-0 device-details-card">
                    <CRow>
                      <CCol class="p-2 m-4">
                        <CButtonGroup class="btn-block">
                          <CButton
                            color="info"
                            square
                            variant="outline"
                            size="sm"
                            class="m-0 p-1"
                            @click="handleManageClick(item)"
                            v-c-tooltip="'Manage Device'"
                          >
                            <div style="">Manage</div>
                          </CButton>
                          <CButton
                            color="info"
                            square
                            variant="outline"
                            size="sm"
                            class="m-0 p-1"
                            @click="handleViewClick(item)"
                            v-c-tooltip="'View Device'"
                          >
                            <div style="">View</div>
                          </CButton>
                        </CButtonGroup>
                      </CCol>
                    </CRow>
                  </CCard>
                </CCol>
              </CRow>
              </CCard>
            </template>
          </DeviceManagementGrid>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import DeviceManagementGrid from "@/components/tables/DeviceManagementGrid";
import IotInfo from "@/components/dashboard/IotInfo";
import DeviceDetails from "@/views/main/device/DeviceDetails";

export default {
  name: "DevicesListView",
  components: {
    DeviceManagementGrid,
    IotInfo,
    DeviceDetails,
  },
  props:{
    inventory:{
      type: Array

    }
  },
  data() {
    return {
      //inventory: [],
    };
  },
  computed: {
    
  },
  methods: {

    handleViewClick(e){
      
      let item = e.item; 
      let path = "/dashboard/device";
      
      this.$router.push({
        path: path,
        params: { device: item.device },
        query: { id: item.device },
      });
    }, 
    handleManageClick(e){
      // TODO: Navigate to An App 
       let item = e.item; 
      let path = `/demo/devices/${item.device}/manage`;
      
      this.$router.push({
        path: path,
        params: { device: item.device },
        
      });
    }, 
    isExpired() {
      if (Date.now() - this.$store.state.dataStore.age > 5000) return true;
      return false;
    },

    getInfo(item) {
      console.log(item);
      let info = { ...item.item };
      return info;
    },

    toggleDetails(item, index) {
      this.$set(item, "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
      /*
      this.$router.push({
        path: "/dashboard/device",
        params: { device: item.device },
        query: { id: item.device },
      });
      */
    },
  },
  mounted: async function () {
    /** On Mounted, Load the  */
    //usersData: usersData.map((item, id) => { return {...item, id}}),
    try {
      if (this.isExpired()) {
        // Only get the Inventory if it's not already loaded
        if (!this.$store.getters.inventory) {
          await this.$app.loadInventory();
        }
        // TODO: Subscribe to Handler to change if the inventory changes
      }
      //this.inventory = this.$store.getters.inventory;
    } catch (err) {
      console.log(err);
    }
  },
};
</script>


<style scoped>
.device-details-card {
  min-height: 100px;
  height: 120px;
}
</style>